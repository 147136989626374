import './css/Global.css';
import LandingPage from './components/LandingPage';
import FloatingNavbar from './components/FloatingNavbar';
import FaqSection from './components/FaqSection';
import OverviewInfoSection from './components/OverviewInfoSection';
import Roadmap from './components/Roadmap';
import BottomFooter from './components/BottomFooter';
import RaritySection from './components/RaritySection';
// import bg from "./resources/bg.PNG"
import sound from "./resources/soundLogoRemove.png"


// Any new components add them here
function App() {
  return (
    <div className="App">
      <LandingPage>
        
      </LandingPage>
    </div>
  );
}

const mute = () => {
  const video = document.getElementById('background-video')
    video.muted ^= 1
}

export default App;
