import "../css/LandingPage.css";
import banner from "../resources/banner.png";
import driving from "../resources/driving.mp4";
import placeholder from "../resources/vidplaceholder.PNG";
import orangutan from "../resources/orangutan.jpg";
import mario from "../resources/mario.mp4";
import gta from "../resources/gta.mp4";
import cruising from "../resources/cruising.mp4";
import wiggle from "../resources/wiggle.png"
import TwitterLogo from '../resources/icons/TwitterLogo.svg';
import TGLogo from '../resources/icons/TelegramLogo.svg';

const LetsGame = () => {
  return (
    <div class="Landing-container">
      <div className="Landing-content-flex-container">
        <img class="medium-width" src={banner}></img>
      <video id="banner" autoPlay loop muted poster={placeholder} width="60%" height="60%">
        <source src={driving}></source>
      </video>
        <img class="full-width" src={wiggle}></img>
        <div class="flex-container-small">
          <video autoPlay loop muted poster={placeholder} width="45%">
            <source src={mario}></source>
          </video>
          <video autoPlay loop muted poster={placeholder} width="45%">
            <source src={gta}></source>
          </video>
        </div>        
        <img class="full-width" src={wiggle}></img>
        <video autoPlay loop muted poster={placeholder} width="80%">
            <source src={cruising}></source>
          </video>
        <img class="full-width" src={wiggle}></img>
        <img class="full-width" src={orangutan}></img>
      </div>
      <div class="flex-ctr-col brown-bg width-100">
        <div class="flex-ctr medium-text">
          <div>Contract Address:</div>
        </div>
        <div class="medium-text">
        HTj3GHardRShLGvoavMvyV5k8NcgRRdJZLiYAkGBXAu7
        </div>
        <div class="flex-ctr medium-text">
        <a href="https://x.com/CtoOmw"> <img class="Navbar-social-logo" alt="TwitterLogo" src={TwitterLogo}></img></a>
        <a href="https://t.me/omwcto"> <img class="Navbar-social-logo" alt="TGLogo" src={TGLogo}></img></a>
        </div>
        <div class="flex-ctr">$OMW</div>
      </div>
      </div>
  );
};

export default LetsGame;
